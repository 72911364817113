import logger from '@knauf-group/ct-shared-nextjs/logger'

export const extractIdFromSlug = (slug: string) => {
  try {
    const slugSegments = slug.split('-')
    return slugSegments.at(-1)
  } catch (error) {
    logger.error(`getIdFromSlug > Invalid slug: '${slug}'`, error)
    return undefined
  }
}
